/* eslint-disable menti-react/filename-convention--jsx */
'use client';

import React from 'react';
import type { Regions, TrackingClient } from '@mentimeter/http-clients';
import { createTrackingClient } from '@mentimeter/http-clients';
import { useParams } from 'next/navigation';
import { getRegionByVoteKey, RegionHttpClients } from '@mentimeter/region';
import type { IntegrationContextValueT } from '../features/integrations';
import { useIntegration } from '../features/integrations';
import { isTryMode } from './isTryMode';
import { getIdentifierJwt } from './identifier';

const apis = {
  regions: {
    eu: {
      quizUrl: process.env.NEXT_PUBLIC_EU_QUIZ_URL,
      blabUrl: process.env.NEXT_PUBLIC_EU_TRACKING_URL,
    },
    us: {
      quizUrl: process.env.NEXT_PUBLIC_US_QUIZ_URL,
      blabUrl: process.env.NEXT_PUBLIC_US_TRACKING_URL,
    },
  },
};

// TODO should be refactored to use object as hash
export const trackEvent = (
  category: string,
  action: string,
  label: string | null = null,
  value = 0,
) => {
  if (typeof window !== 'undefined' && window.gaTrackAll) {
    if (value) {
      window.gaTrackAll('send', 'event', category, action, label, value);
      return true;
    }
    window.gaTrackAll('send', 'event', category, action, label);
    return true;
  }
  return false;
};

export const trackCustomFbEvent = ({ eventName }: { eventName: string }) => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq('trackCustom', eventName);
    return true;
  }
  return false;
};

export type TrackVoteT = () => void;

export const useTrackVote = () => {
  const { integrationId }: IntegrationContextValueT = useIntegration();
  return React.useCallback(() => {
    if (isTryMode()) {
      return trackEvent('event status', 'Cast vote', 'Try mode');
    }
    if (integrationId) {
      return trackEvent('event status', 'Cast vote', integrationId);
    }
    return trackEvent('event status', 'Cast vote');
  }, [integrationId]);
};

type RegionClients = RegionHttpClients<TrackingClient>;
type TrackVoter = TrackingClient['trackVoter'];

const TrackingContext = React.createContext<
  ((data: Parameters<TrackVoter>[0], region?: Regions) => any) | undefined
>(undefined);

const getInteractionSource = (
  userAgent: string,
  isTryMode: boolean,
  integrationId: string,
) => {
  if (userAgent.match(/android-react-native-app/)) {
    /* cant see the difference between apps today, we'd need to submit new builds to the Android + App Store */
    return 'Native app';
  }
  if (isTryMode) {
    return 'Try mode';
  }

  return integrationId;
};

export const TrackingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const client = React.useRef<RegionClients | undefined>(undefined);
  const singletonEvents = React.useRef<Record<string, boolean>>({
    'Joined presentation': false,
  });
  const queue = React.useRef<Array<Parameters<TrackVoter>[0]>>([]);
  const { integrationId } = useIntegration();
  //
  const params = useParams()?.voteParams as string[];
  const voteKey = params ? params[0]! : '';

  React.useEffect(() => {
    if (!client.current) {
      return;
    }

    const interactionSource = getInteractionSource(
      navigator.userAgent,
      isTryMode(),
      // @ts-expect-error-auto TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      integrationId,
    );

    client.current.setDefaultClientByRegion(getRegionByVoteKey(voteKey));
    client.current.client.setGlobalProperties({
      'vote key': voteKey,
      'interaction source': interactionSource,
    });
  }, [integrationId, voteKey]);

  React.useEffect(() => {
    const initClient = async () => {
      const jwt = await getIdentifierJwt();

      const http = new RegionHttpClients({
        eu: createTrackingClient({
          baseURL: apis.regions.eu.blabUrl,
          voterToken: jwt,
        }),
        us: createTrackingClient({
          baseURL: apis.regions.us.blabUrl,
          voterToken: jwt,
        }),
      });

      const interactionSource = getInteractionSource(
        navigator.userAgent,
        isTryMode(),
        // @ts-expect-error-auto TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        integrationId,
      );

      http.setDefaultClientByRegion(getRegionByVoteKey(voteKey));
      http.client.setGlobalProperties({
        'vote key': voteKey,
        'interaction source': interactionSource,
      });
      queue.current.forEach((params) => http.client.trackVoter(params));
      client.current = http;
      queue.current = [];
    };

    if (!client.current) {
      initClient();
    }
  }, [integrationId, voteKey]);

  const trackVoter = React.useCallback(
    async (args: Parameters<TrackVoter>[0], region?: Regions) => {
      if (singletonEvents.current[args.event]) {
        return;
      }
      if (singletonEvents.current[args.event] === false) {
        singletonEvents.current[args.event] = true;
      }
      if (!client.current) {
        queue.current.push(args);
        return;
      }

      if (region) {
        await client.current.getClientByRegion(region).trackVoter(args);
      } else {
        await client.current.client.trackVoter(args);
      }
    },
    [],
  );

  return (
    <TrackingContext.Provider value={trackVoter}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTrack = () => {
  const value = React.useContext(TrackingContext);
  if (!value) {
    throw new Error('called useTrack without a TrackingProvider');
  }
  return value;
};
