import {
  blackAlpha700,
  blue100,
  blue50,
  blue500,
  blueDark500,
  gray500,
  gray600,
  green100,
  green500,
  greenDark800,
  pinkDark50,
  pinkDark100,
  pinkDark600,
  pinkDark800,
  purple50,
  purpleDark500,
  purpleDark800,
  whiteBase,
  yellow100,
  yellow500,
  blue200,
  blue800,
  gray1050,
  gray1100,
  gray1300,
  pinkDark200,
  purpleDark100,
  yellowDark800,
  yellowLight50,
  pinkDark700,
  blackBase,
  gray1400,
  gray1200,
  gray1000,
  gray700,
  gray900,
  purpleLight100,
  yellowDark700,
  greenLight50,
  yellowLight100,
  gray800,
  green300,
  purpleLightAlpha100,
  purpleDark300,
  neutralAlpha700,
  neutralAlpha900,
  purpleDark200,
  gray100,
  purpleLight200,
  blue700,
  pinkDark300,
  purple100,
  green200,
  purpleDark700,
  purpleDark600,
  blue600,
  purple800,
  whiteAlpha300,
  whiteAlpha700,
  whiteAlpha800,
  purpleDark50,
} from '../darkPalette';
import type { Colors } from '../colors.types';

/** IMPORTANT! Any changes to dark needs to be reflected in tailwind!
 * 1. Apply the same changes to the respective theme in colorTokenToPaletteMap.ts, in playground-web
 * 2. Run localhost:1000/tailwind (playground-web) with the theme settings and use "console.log(colorTokenMap)"
 * 3. Update the dark theme class framework colors in globals.css
 */
export const dark: Colors = {
  bg: gray1300,
  bgStrong: gray1400,
  bgOverlay: blackAlpha700,
  surface: gray1200,
  surfaceHover: gray1050,
  surfaceActive: gray1200,
  surfaceChecked: purple50,
  surfaceCheckedHover: purpleLight200,
  surfaceRaised: gray1100,
  surfaceSunken: gray1400,
  surfaceOverlay: gray1100,
  surfaceOverlayTransparent: neutralAlpha700,
  surfaceOverlayTransparentHover: neutralAlpha900,
  highContrast: blackBase,
  border: gray900,
  borderWeak: gray1100,
  borderWeaker: gray1200,
  borderStrong: gray700,
  borderBrandWeakest: blue100,
  borderPrimaryWeakest: gray800,
  borderNegativeWeakest: pinkDark100,
  borderNeutralWeakest: gray900,
  borderInfoWeakest: purpleLight100,
  borderPositiveWeakest: green100,
  borderNoticeWeakest: yellow100,
  borderSecondaryWeakest: purpleDark100,
  borderDisabled: gray1100,
  borderTransparent: whiteAlpha300,
  brand: blue500,
  brandWeak: blue200,
  brandWeakest: blue50,
  text: gray100,
  textLink: whiteBase,
  textWeak: whiteAlpha800,
  textWeaker: whiteAlpha700,
  textNegative: pinkDark700,
  textPrimary: gray100,
  textDisabled: gray900,
  onBrand: whiteBase,
  onBrandWeak: blue800,
  onBrandWeakest: blue800,
  onDisabled: gray700,
  onDisabledStrong: gray700,
  onInfo: whiteBase,
  onInfoWeak: purpleDark800,
  onInfoWeakest: purpleDark800,
  onInteractive: whiteBase,
  onHighContrast: gray100,
  onNeutral: whiteBase,
  onNeutralWeak: whiteBase,
  onNeutralWeakest: whiteBase,
  onNegative: whiteBase,
  onNegativeWeak: pinkDark800,
  onNegativeWeakest: pinkDark800,
  onNotice: blueDark500,
  onNoticeWeak: yellowDark700,
  onNoticeWeakest: yellowDark800,
  onPositive: blueDark500,
  onPositiveWeak: greenDark800,
  onPositiveWeakest: greenDark800,
  onPrimary: gray1300,
  onPrimaryWeak: gray1300,
  onPrimaryWeakest: gray1300,
  onSecondary: whiteBase,
  onSecondaryWeak: purple800,
  onSecondaryWeakest: purple800,
  primary: gray100,
  primaryWeak: gray600,
  primaryWeakest: gray700,
  info: purpleDark500,
  infoWeak: purpleLight200,
  infoWeakest: purpleDark50,
  neutral: gray1000,
  neutralWeak: gray1100,
  neutralWeakest: gray1100,
  negative: pinkDark600,
  negativeWeak: pinkDark200,
  negativeWeakest: pinkDark50,
  positive: green500,
  positiveWeak: green100,
  positiveWeakest: greenLight50,
  secondary: purpleDark500,
  secondaryWeak: purpleLight100,
  secondaryWeakest: purple50,
  notice: yellow500,
  noticeWeak: yellowLight100,
  noticeWeakest: yellowLight50,
  disabled: gray1000,
  disabledStrong: gray900,
  interactive: blue500,
  interactiveFocused: purpleLightAlpha100,
  // COMPONENT TOKENS
  buttonBrandBg: blue500,
  buttonBrandBgHover: blue600,
  buttonBrandBgActive: blue700,
  buttonNegativeBg: pinkDark600,
  buttonNegativeBgHover: pinkDark300,
  buttonNegativeBgActive: pinkDark200,
  buttonNegativeWeakBg: pinkDark100,
  buttonNegativeWeakBgHover: pinkDark300,
  buttonNegativeWeakBgActive: pinkDark200,
  buttonOutlineBgHover: gray1000,
  buttonOutlineBgActive: gray900,
  buttonOutlineBgChecked: purpleDark100,
  buttonOutlineBgCheckedHover: purple100,
  buttonOutlineBgCheckedActive: purple50,
  buttonPositiveBg: green500,
  buttonPositiveBgHover: green300,
  buttonPositiveBgActive: green200,

  buttonPrimaryBg: gray500,
  buttonPrimaryBgHover: gray600,
  buttonPrimaryBgActive: gray700,
  buttonPrimaryBgChecked: purpleDark700,
  buttonPrimaryBgCheckedHover: purpleDark600,
  buttonPrimaryBgCheckedActive: purpleDark500,

  buttonSecondaryBgHover: gray1000,
  buttonSecondaryBgActive: gray900,
  buttonSecondaryBgChecked: purpleDark100,
  buttonSecondaryBgCheckedHover: purpleDark200,
  buttonSecondaryBgCheckedActive: purpleDark300,
  buttonTertiaryBg: gray900,
  buttonTertiaryBgHover: gray1000,
  buttonTertiaryBgActive: gray1100,
  buttonTertiaryBgChecked: purpleDark700,
  buttonTertiaryBgCheckedHover: purpleDark600,
  buttonTertiaryBgCheckedActive: purpleDark500,
  buttonPrimaryReverseBg: gray1200,
  buttonPrimaryReverseBgHover: gray1300,
  buttonPrimaryReverseBgActive: gray1400,
  buttonPrimaryReverseBgChecked: purpleDark500,
  buttonPrimaryReverseBgCheckedHover: purpleDark300,
  buttonPrimaryReverseBgCheckedActive: purpleDark200,
  inputBg: gray1100,
  inputBgActive: gray1200,
};
